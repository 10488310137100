import StarAlt from "components/Icons/StarAlt";
import UITooltip from "./UITooltip";

const ProductTag = ({
  text,
  textColor,
  backgroundColor,
  isAbsolute = true,
  isSmall = false,
  withStarIcon = false,
}: {
  text: string;
  textColor: string;
  backgroundColor: string;
  isAbsolute?: boolean;
  isSmall?: boolean;
  withStarIcon?: any;
}) => (
  <div
    className={`top-[10px] right-[10px] z-[11] h-[20px] rounded-[12px]  px-[12px]  ${isSmall ? 'font-normal text-[12px]' : 'font-medium text-[12px]'} w-fit leading-[18px] ${isAbsolute && 'absolute'}`}
    style={{
      color: textColor,
      backgroundColor: backgroundColor,
      width: 'fit-content'
    }}>
    {withStarIcon ? <UITooltip place="top"
      border={true}
      borderColour={'#D7D8D7'}
      textColour={'#000'}
      backgroundColour={'#FFF'}
      messageWrapperClassName={'mb-3 mt-3'}
      wrapperClassName='ml-2'
      tooltipWrapperClassName={'!max-w-[300px] leading-normal font-normal justify-center items-center md:before-selector-override md:after-selector-override shadow-tooltip'}
      message={<>
        <div className='mb-2 font-medium'>Beeswax glasses come with a bonus extra set of arms to mix and match your frames!</div> Add your glasses to cart, and then tell us your extra colour choice in the notes at check-out, or we’ll select a surprise colour for you and add to your package! Ends 19th July.
      </>}
      id={'beeswax-tt'}>
      <div className='whitespace-nowrap'>
        {withStarIcon && <StarAlt fill="#fff" className="-ml-2 mr-1 -mt-0.5 inline-block" />}
        {text}
      </div>
    </UITooltip> : <div className='whitespace-nowrap'>
      {withStarIcon && <StarAlt fill="#fff" className="-ml-2 mr-1 -mt-0.5 inline-block" />}
      {text}
    </div>}


  </div>
);

export default ProductTag;
