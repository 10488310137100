var removeLinks: any = {
    ca: {
        remove: [
            'progressives',
            'multifocals',
            'shop/accessories/pin-garage-rainbow-collection',
            'shop/accessories/pin-garage-neutral-collection',
            'shop/accessories/tote-bag',
            'shop/accessories/cleaning-cloths-set',
            'shop/accessories/cleaning-cloths-single-pair-large',
            'shop/accessories/cleaning-cloths-all-colours',
            'shop/accessories/cleaning-cloths-diagonal-pattern',
            'shop/accessories/zeiss-anti-fog',
            'shop/accessories/hoya-no-fog-reusable-wipe',
            'shop/accessories/tote-bags',
            'shop/accessories/pin-garages',
            'shop/accessories/cleaning-cloths',
            'shop/contact-lenses/purevision-multifocal-6-pack',
            'shop/contact-lenses/brand/purevision',
            'shop/contact-lenses/purevision-6-pack',
            'shop/contact-lenses/dailies-total1-astigmatism-30-pack',
            'shop/contact-lenses/acuvue-oasys-2-weekly-6-pack',
            'shop/contact-lenses/acuvue-define-30-pack',
            'shop/contact-lenses/acuvue-trueye-90-pack',
            'shop/contact-lenses/cair-toric-6-pack',
            'shop/contact-lenses/brand/cair',
            'shop/contact-lenses/acuvue-trueye-30-pack',
            'shop/contact-lenses/cair-6-pack',
            'shop/contact-lenses/biofinity-toric-multifocal-6-pack',
            'shop/contact-lenses/biofinity-3-pack',
            'shop/contact-lenses/biofinity-toric-3-pack',
            'shop/contact-lenses/biomedics-90-pack',
            'shop/contact-lenses/brand/biomedics',
            'shop/contact-lenses/biomedics-30-pack',
            'shop/contact-lenses/proclear-multifocal-30-pack',
            'shop/contact-lenses/proclear-30-pack',
            'shop/contact-lenses/myday-toric-30-pack',
            'shop/contact-lenses/myday-30-pack',
            'shop/contact-lenses/focus-dailies-all-day-comfort-90-pack',
            'shop/contact-lenses/soflens-66-toric-for-astigmatism',
            'shop/contact-lenses/brand/soflens',
            'shop/contact-lenses/purevision-2-for-presbyopia',
            'shop/contact-lenses/soflens-multifocal',
            'shop/contact-lenses/purevision-2-for-astigmatism',
            'shop/contact-lenses/soflens-daily-disposable',
            'shop/contact-lenses/purevision-2',
            'shop/contact-lenses/soflens-daily-disposable-for-astigmatism',
            'shop/contact-lenses/air-optix-plus-hydraglyde-multifocal',
            'shop/contact-lenses/air-optix-plus-hydraglyde-for-astigmatism',
            'shop/contact-lenses/air-optix-plus-hydraglyde-3-pack',
            'shop/contact-lenses/focus-dailies-all-day-comfort-30-pack',
            'shop/accessories/zeiss-antifog',
            'career'
        ],
        only: [
            'shop/accessories/ronor-anti-fog-wipes',
            'shop/contact-lenses/precision1-astigmatism-90-pack',
            'shop/contact-lenses/precision1-astigmatism-30-pack',
            'shop/contact-lenses/acuvue-vita-6-pack',
            'shop/contact-lenses/biofinity-6-pack',
            'shop/contact-lenses/biofinity-toric-6-pack',
            'shop/contact-lenses/ultra-toric-multifocal-6-pack',
            'shop/contact-lenses/air-optix-plus-hydraglyde-multifocal-6-pack',
            'shop/contact-lenses/air-optix-plus-hydraglyde-astigmatism-6-pack',
            'shop/contact-lenses/biotrue-oneday-for-astigmatism-90-pack',
            'shop/contact-lenses/ultra-toric-multifocal-12-month-bundle',
            'shop/contact-lenses/ultra-toric-6-month-bundle',
            'shop/contact-lenses/ultra-toric-12-month-bundle',
            'shop/contact-lenses/ultra-toric-multifocal-6-month-bundle',
            'shop/contact-lenses/ultra-presbyopia-multifocal-6-month-bundle',
            'shop/contact-lenses/ultra-presbyopia-multifocal-12-month-bundle',
            'shop/contact-lenses/ultra-6-month-bundle',
            'shop/contact-lenses/ultra-12-month-bundle',
            'shop/contact-lenses/biotrue-oneday-6-month-bundle',
            'shop/contact-lenses/biotrue-oneday-12-month-bundle',
            'shop/contact-lenses/biotrue-oneday-for-astigmatism-6-month-bundle',
            'shop/contact-lenses/biotrue-oneday-for-astigmatism-12-month-bundle',
            'shop/contact-lenses/biotrue-oneday-for-presbyopia-6-month-bundle',
            'shop/contact-lenses/biotrue-oneday-for-presbyopia-12-month-bundle',
            'shop/contact-lenses/ultra-one-day-12-month-bundle',
            'shop/contact-lenses/ultra-one-day-6-month-bundle',
            'shop/contact-lenses/alcon-total30-6-pack',
            'shop/contact-lenses/brand/total30',
            'shop/contact-lenses/precision1-astigmatism-90-pack',
            'shop/contact-lenses/precision1-astigmatism-30-pack',
            'shop/contact-lenses/acuvue-vita-6-pack',
            'shop/contact-lenses/biofinity-6-pack',
            'shop/contact-lenses/biofinity-toric-6-pack',
            'shop/contact-lenses/ultra-toric-multifocal-6-pack',
            'shop/contact-lenses/air-optix-plus-hydraglyde-multifocal-6-pack',
            'shop/contact-lenses/air-optix-plus-hydraglyde-astigmatism-6-pack',
            'shop/contact-lenses/biotrue-oneday-for-astigmatism-90-pack',
            'shop/contact-lenses/acuvue-oasys-astigmatism-6-pack',
            'book-an-eye-exam'
        ]
    },
    international: {
        remove: [
            'progressives',
            'multifocals',
            'giftcard-sale',
            'career'
        ],
        only: [
        ]
    },
    au: {
        remove: [
            'progressives',
            'multifocals',
        ],
        only: [
            'book-an-optometrist'
        ]
    },
    nz: {
        remove: [
            'progressives',
            'multifocals',
            'shop/contact-lenses',
            'career',
            /shop\/contact-lenses\/[a-z0-9]+(?:-[a-z0-9]+)*$/
        ],
        only: [
        ]
    },
}

export const getProperAlternate = (region: any, link: string) => {
    var check = true;

    removeLinks[region]['remove'].map((removeRegion: any) => {
        if (link.match(removeRegion)) {
            check = false;
        }
    });

    Object.keys(removeLinks).map((rg: any) => {
        removeLinks[rg]['only'].map((removeRegion: any) => {
            if (link.match(removeRegion) && rg != region) {
                check = false;
            }
        });
    });

    return check;
}