export const getGlassesData = (item: any) => {
  var compositeData = getGlassesCompositeData(item);
  var lensTitle = getGlassesLensTitle(item);

  var lensData: any = Object.values(
    compositeData
  ).filter((e: any) => e.title === 'Lens')[0];

  var prescription: any = null;

  if (lensData.prescription) {
    prescription = lensData.prescription;
  }

  const armData: any = Object.values(
    compositeData
  ).filter((e: any) => e.title === 'Arms')[0];

  const frameData: any = Object.values(
    compositeData
  ).filter((e: any) => e.title === 'Frame')[0];

  const frameColor = (frameData.attributes.attribute_pa_color);
  const armColor = (armData.attributes.attribute_pa_color);

  const frameSize = (frameData.attributes.attribute_pa_size);
  const armSize = (armData.attributes.attribute_pa_size);

  var glassesData: any = {
    lensTitle: lensTitle,
    lensType: (lensData.attributes.attribute_pa_treatment),
    thickness: lensData.attributes['attribute_pa_lens-thickness'],
    prescription: prescription,
    purpose: lensData.purpose,
    armSize: armSize,
    frameSize: frameSize,
    frameColor: frameColor,
    armColor: armColor
  }

  return glassesData;
}

export const getLensAttributes = (lens_thickness_selected: any) => {
  var attributes: any = {};
  if (lens_thickness_selected?.variation?.meta.attribute_pa_lens_thickness) {
    attributes['attribute_pa_lens-thickness'] = lens_thickness_selected?.variation?.meta.attribute_pa_lens_thickness;
  }
  if (lens_thickness_selected?.variation?.meta.attribute_pa_treatment) {
    attributes['attribute_pa_treatment'] = lens_thickness_selected?.variation?.meta.attribute_pa_treatment;
  }
  if (lens_thickness_selected?.variation?.meta.attribute_pa_stock_type) {
    attributes['attribute_pa_stock-type'] = lens_thickness_selected?.variation?.meta.attribute_pa_stock_type;
  }
  return attributes;
}


export const getDresdenGOData = (item: any) => {
  const armData: any = Object.values(
    JSON.parse(
      item[0].extraData.filter(
        (eData: any) => eData.key === 'composite_data'
      )[0].value
    )
  ).filter((e: any) => e.title === 'Arms')[0];

  const frameData: any = Object.values(
    JSON.parse(
      item[0].extraData.filter(
        (eData: any) => eData.key === 'composite_data'
      )[0].value
    )
  ).filter((e: any) => e.title === 'Frame')[0];

  const lensData: any = Object.values(
    JSON.parse(
      item[0].extraData.filter(
        (eData: any) => eData.key === 'composite_data'
      )[0].value
    )
  ).filter((e: any) => e.title === 'Lens')[0];

  const frameColor = (frameData.attributes.attribute_pa_color);
  const armColor = (armData.attributes.attribute_pa_color);

  const frameSize = (frameData.attributes.attribute_pa_size);
  const armSize = (armData.attributes.attribute_pa_size);

  var frameSetData: any = {
    armSize: armSize,
    frameSize: frameSize,
    frameColor: frameColor,
    armColor: armColor,
    lensData: lensData
  }

  return frameSetData;
}
export const getFramesData = (item: any) => {
  const armData: any = Object.values(
    JSON.parse(
      item[0].extraData.filter(
        (eData: any) => eData.key === 'composite_data'
      )[0].value
    )
  ).filter((e: any) => e.title === 'Arms')[0];

  const frameData: any = Object.values(
    JSON.parse(
      item[0].extraData.filter(
        (eData: any) => eData.key === 'composite_data'
      )[0].value
    )
  ).filter((e: any) => e.title === 'Frame')[0];

  const frameColor = (frameData.attributes.attribute_pa_color);
  const armColor = (armData.attributes.attribute_pa_color);

  const frameSize = (frameData.attributes.attribute_pa_size);
  const armSize = (armData.attributes.attribute_pa_size);

  var frameSetData: any = {
    armSize: armSize,
    frameSize: frameSize,
    frameColor: frameColor,
    armColor: armColor
  }

  return frameSetData;
}

const getGlassesCompositeData = (glasses: any) => {
  var composite_data: any = {};
  glasses.map((item: any) => {
    item.extraData.map((eData: any) => {
      if (eData.key === 'composite_data' && item.variation) {
        composite_data = JSON.parse(eData.value);
      }
    })
  });
  return composite_data;
}

const getGlassesLensTitle = (glasses: any) => {
  var lensTitle: string = '';
  glasses.map((item: any) => {
    item.extraData.map((eData: any) => {
      if (eData.key === 'composite_data' && item.variation) {
        lensTitle = item.variation.node.name;
      }
    })
  });
  return lensTitle;
} 