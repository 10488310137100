

import { create } from 'zustand'


export const useProductStore = create<any>((set:any) => ({
    activeSelection: 'purpose',
    activeSelectionIdx: 0,
    priscriptionFormValues:{},
    isActiveLensSelectionPanel: false,
    uploadFormOpen:false,
    uploadFormFileSelected:false,
    setActiveSelection: (selection:string) => set((state:any) => ({
        ...state,
        activeSelection: selection
    })),
    setActiveSelectionIdx: (selection:string) => set((state:any) => ({
        ...state,
        activeSelectionIdx: selection
    })),
    setPriscriptionFormValues(values:any) {
        set((state:any) => ({
            ...state,
            priscriptionFormValues: values
        }))
    },
    setIsActiveLensSelectionPanel:(isActive:boolean) => set((state:any) => ({
        ...state,
        isActiveLensSelectionPanel: isActive
    })),
    setUploadFormOpen:(isOpen:boolean) => set((state:any) => ({
        ...state,
        uploadFormOpen: isOpen
    })),
    setUploadFormFileSelected:(isFileSelected:boolean) => set((state:any) => ({
        ...state,
        uploadFormFileSelected: isFileSelected
    })),
}));